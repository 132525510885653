import { Injectable } from '@angular/core';

/**
 * Service to handle authentication-related operations using Firebase.
 */
@Injectable({
  providedIn: 'root',
})
export class AuthenticationService {
  /**
   * Sets a cookie with a given name, value, and expiration days.
   * @param {string} name - The name of the cookie.
   * @param {string} value - The value of the cookie.
   * @param {number} days - The number of days until the cookie expires.
   */
  // setCookie(name: string, value: string, days: number): void {
  //   const date = new Date();
  //   date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
  //   const expires = `expires=${date.toUTCString()}`;
  //   document.cookie = `${name}=${value};${expires};path=/`;
  // }
  setCookie(name: string, value: string, days: number): void {
    document.cookie = `${name}=${value};path=/`;
  }

  /**
   * Gets the value of a cookie with a given name.
   * @param {string} name - The name of the cookie.
   * @returns {string} The value of the cookie, or an empty string if the cookie does not exist.
   */
  getCookie(name: string): string {
    const nameEQ = `${name}=`;
    const ca = document.cookie.split(';');
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i].trim();
      if (c.startsWith(nameEQ)) {
        return c.substring(nameEQ.length);
      }
    }
    return '';
  }

  /**
   * Checks if a cookie with a given name exists.
   * @param {string} name - The name of the cookie.
   * @returns {boolean} True if the cookie exists, otherwise false.
   */
  checkCookie(name: string): boolean {
    return this.getCookie(name) !== '';
  }

  /**
   * Deletes a cookie with a given name.
   * @param {string} name - The name of the cookie.
   */
  deleteCookie(name: string): void {
    document.cookie = `${name}=;expires=Thu, 01 Jan 1970 00:00:00 UTC;path=/;`;
  }
}
